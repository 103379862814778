import Vue from 'vue'
import RegistrationsForm from '../components/registrations_form.vue'

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('signUpForm');
  if(el) {
    var app = new Vue({
      el,
      render: h => h(RegistrationsForm)
    });
  }
});
