<template>
  <div>
    <div v-if="current_step == 'email'">
      <h1>{{i18n('register.hi')}}!</h1>
      <p class="text-center">
        <b>{{i18n('register.first_step.first_line')}}</b>
        <br />
        {{i18n('register.first_step.on_homestify')}}
      </p>
      <a class="btn btn-fb btn-block" href="/users/auth/facebook">
          <svg width="14" height="14" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" fill="#fff"></path></svg>
          {{i18n('register.fb')}}
      </a>
      <p class="text-center my-3">{{i18n('register.first_step.or')}}</p>
      <form action="/users" accept-charset="UTF-8" method="post" v-on:submit.prevent="submitEmail">
        <div class="form-group">
          <label for="user_registrations_email">{{i18n('register.email')}}</label>
          <input autofocus="autofocus" autocomplete="email" id="registrations_email" type="email" v-model="email" name="user[email]" class="form-control" v-bind:class="{'is-invalid': errors.email}">
          <div class="invalid-feedback" v-if="errors.email">
            <span v-for="error in errors.email">{{error}}</span>
          </div>
        </div>
        <p>
            {{i18n('register.first_step.terms')}}
            <a v-bind:href="langtag_path+'/terms-of-use'" target="_blank">{{i18n('register.first_step.terms_link')}}</a>
            {{i18n('register.first_step.and')}}
            <a v-bind:href="langtag_path+'/privacy-policy'" target="_blank">{{i18n('register.first_step.privacy')}}</a>
        </p>

        <div class="actions">
          <button type="submit" class="btn btn-block btn-danger">{{i18n('register.first_step.submit')}}</button>
        </div>
      </form>
    </div>

    <div v-if="current_step == 'password'">
      <h1>{{i18n('register.second_step.title')}}</h1>
      <p class="text-center">
        <b>{{i18n('register.second_step.first_line')}}</b>
        <br />
        {{i18n('register.second_step.second_line')}}
        <br />
        {{i18n('register.second_step.third_line')}}
      </p>

      <form action="/users/confirmations" accept-charset="UTF-8" method="put" v-on:submit.prevent="submitPassword">
        <div class="form-group">
          <label for="user_confirmations_password">Password</label>
          <input id="user_confirmations_password" type="password" v-model="password" name="user[password]" class="form-control password" v-bind:class="[(errors.password ? 'is-invalid' : null), ((password_strength != null) ? password_strength_classes[password_strength] : null)]" v-on:change="passwordStrength" v-on:keyup="passwordStrength">
          <div class="invalid-feedback" v-if="errors.password">
            <span v-for="error in errors.password">{{error}}</span>
          </div>
          <div class="row">
            <div class="col-6">
              <small class="form-text"><span v-if="password && password.length < 6"> {{i18n('register.second_step.password_text').replace('%{count}', (6 - password.length))}}</span>&nbsp;</small>
            </div>
            <div class="col-6">
              <div class="text-right">
                <small class="form-text">&nbsp;<span v-if="password_strength != null">{{password_strength_array[password_strength]}}</span></small>
              </div>
            </div>
          </div>
        </div>

        <div class="actions">
          <button type="submit" class="btn btn-block btn-danger">{{i18n('register.second_step.submit')}}</button>
        </div>
      </form>
    </div>

    <div v-if="current_step == 'account_type'">
      <h1>{{i18n('register.third_step.title')}}</h1>
      <p class="text-center">
        {{i18n('register.third_step.first_line')}}
      </p>

      <div class="mb-3">
        <select class="form-control" v-model="account_type">
          <option v-for="(name, type) in account_types" v-bind:value="type">{{name}}</option>
        </select>
        <!-- <div v-for="(name, type) in account_types" class="mb-1">
          <button type="button" class="btn btn-block" v-on:click="account_type = type" v-bind:class="[(type == account_type) ? 'btn-success' : 'btn-light']">{{name}}</button>
        </div> -->
        <div class="text-danger" v-if="errors.role">
          <span v-for="error in errors.role">{{error}}</span>
        </div>
        <div class="row mt-2" v-if="account_type != 'user'">
          <div class="form-group col-md-6">
            <label for="team_name">{{i18n('change_account.name')}}</label>
            <input type="text" class="form-control" name="team_name" :placeholder="i18n('change_account.name')" v-bind:class="[(errors.team_name ? 'is-invalid' : null)]" v-model="team_name">
            <div class="text-danger" v-if="errors.team_name">
              <span v-for="error in errors.team_name">{{error}}</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="licence_number">{{i18n('change_account.licence_number')}}</label>
            <input type="text" class="form-control" name="licence_number" :placeholder="i18n('change_account.licence_number')" v-model="licence_number">
          </div>
        </div>
      </div>

      <div class="actions">
        <button type="button" v-on:click="submit" class="btn btn-block btn-danger">{{i18n('register.third_step.submit')}}</button>
      </div>
    </div>

    <div v-if="current_step == 'success'">
      <h1>{{account_types[account_type]}}</h1>
      <p>
        {{success_message}}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import i18nmixin from '../customs/i18nmixin';
import jstz from 'jstimezonedetect';
export default {
  mixins: [i18nmixin],
  data: function () {
    return {
      email: null,
      errors: {},
      password: null,
      password_strength: null,
      password_strength_array: [
        this.i18n('passwords.bad'),
        this.i18n('passwords.weak'),
        this.i18n('passwords.good'),
        this.i18n('passwords.strong')
      ],
      password_strength_classes: [
        'password--bad',
        'password--weak',
        'password--good',
        'password--strong'
      ],
      current_step: 'email',
      account_type: 'user',
      team_name: null,
      licence_number: null,
      terms: false,
      account_types: {
        'user': this.i18n('accounts.user'),
        'agent': this.i18n('accounts.agency'),
        'developer': this.i18n('accounts.developer'),
      },
      langtag_path: window.langtag_path
    }
  },
  methods: {
    reset() {
      this.email = null;
      this.errors = {};
    },
    submitEmail() {
      var tz = jstz.determine();
      console.log(tz);
      if(!this.email || this.email.length < 1) {
        Vue.set(this.errors, 'email', [this.i18n('errors.blank')]);
        return;
      }
      this.current_step = 'password';
    },
    passwordStrength() {
      Vue.set(this.errors, 'password', null);
      if(this.password.length == 0) {
        this.password_strength = null;
        return;
      }
      var sum = 0;
      if(this.password.length < 6) {
        this.password_strength = sum;
        return;
      }
      sum = this.password.length;
      if(/[a-z]+/.test(this.password)) {
        sum += 6;
      }
      if(/[A-Z]+/.test(this.password)) {
        sum += 6;
      }
      if(/[0-9]+/.test(this.password)) {
        sum += 6;
      }
      if(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.password)) {
        sum += 6;
      }
      this.password_strength = Math.max(0, Math.min(3, Math.floor(sum/12.0)));
    },
    submitPassword() {
      if(!this.password || this.password.length < 6) {
        Vue.set(this.errors, 'password', [this.i18n('errors.to_short')]);
        return;
      }
      this.current_step = 'account_type';
    },
    submit() {
      if(this.account_type != 'user') {
        if(!this.team_name || this.team_name.length < 1) {
          Vue.set(this.errors, 'team_name', [this.i18n('errors.blank')]);
          return;
        }
      }
      this.errors = {};
      window.axios.post(window.langtag_path+'/users.json', {
        user: {
          email: this.email,
          password: this.password,
          password_confirmation: this.password,
          role: this.account_type,
          team_name: this.team_name,
          licence_number: this.licence_number
        },
      })
      .then((response) => {
        this.success_message = response.data.message;
        this.current_step = 'success';
        this.reset();
      })
      .catch((error) => {
        if(error.response.status == 401) {
          this.errors = error.response.data.errors;
          if(this.errors.email) {
            this.current_step = 'email';
            return
          }
          if(this.errors.password) {
            this.current_step = 'password';
            return
          }
        }
      });
    }
  }
}
</script>
